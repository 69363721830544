import axios from "axios";
import table_exp from "@/components/table_exp/table_exp.vue";

let axios_config = require("./config")

const props = ["formdatato"]
const emits = []
const components = {
    table_exp
}

const data = function () {
    return {
        activeName: "1",
        tab_list: [
            {
                name: "默认",
                label: "默认"
            }
        ],
        componentname: "table_exp"
    }
}

function mounted() {
    // 解构数据
    const {url, method, data, then} = axios_config.mounted;
    // 执行转译data
    let new_data = {
        "target_serve": data["target_serve"],
        "target_table": data["target_table"],
        "serve_data": {}
    }
    for (const key in data["serve_data"]) {
        let value = data["serve_data"][key]
        if (value.includes("this.")) {
            if (data["serve_data"].hasOwnProperty(key)) {
                new_data["serve_data"][key] = eval(value)
            }
        } else {
            new_data["serve_data"][key] = value
        }
    }
    // 发起请求
    axios({
        method,
        url,
        data: new_data,
    }).then(res => {
        // 执行回调函数（then回调）
        eval(then)
    })
}

const methods = {
    handleClick() {
        // 解构数据
        const {url, method, data, then} = axios_config.handleClick;
        // 执行转译data
        let new_data = {
            "target_serve": data["target_serve"],
            "target_table": data["target_table"],
            "serve_data": {}
        }
        for (const key in data["serve_data"]) {
            let value = data["serve_data"][key]
            if (value.includes("this.")) {
                if (data["serve_data"].hasOwnProperty(key)) {
                    new_data["serve_data"][key] = eval(value)
                }
            } else {
                new_data["serve_data"][key] = value
            }
        }
        // 发起请求
        axios({
            method,
            url,
            data: new_data,
        }).then(res => {
            // 执行回调函数（then回调）
            eval(then)
        })
    }
}

export default {
    data: data,
    methods: methods,
    mounted: mounted,
    components: components,
    props: props,
    emits: emits
}