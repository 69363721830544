import rightNav from "./components/rightNav/rightNav.vue";
import form_item53 from './components/form_item53/form_item53.vue';
import form_item52 from './components/form_item52/form_item52.vue';
import table_exp26 from './components/table_exp26/table_exp26.vue';
import form_item51 from './components/form_item51/form_item51.vue';
import form_item50 from './components/form_item50/form_item50.vue';
import table_exp25 from './components/table_exp25/table_exp25.vue';
import form_item49 from './components/form_item49/form_item49.vue';
import form_item48 from './components/form_item48/form_item48.vue';
import table_exp24 from './components/table_exp24/table_exp24.vue';
import form_item47 from './components/form_item47/form_item47.vue';
import form_item46 from './components/form_item46/form_item46.vue';
import table_exp23 from './components/table_exp23/table_exp23.vue';
import form_item45 from './components/form_item45/form_item45.vue';
import form_item44 from './components/form_item44/form_item44.vue';
import table_exp22 from './components/table_exp22/table_exp22.vue';
import form_item43 from './components/form_item43/form_item43.vue';
import form_item42 from './components/form_item42/form_item42.vue';
import table_exp21 from './components/table_exp21/table_exp21.vue';
import form_item41 from './components/form_item41/form_item41.vue';
import form_item40 from './components/form_item40/form_item40.vue';
import table_exp20 from './components/table_exp20/table_exp20.vue';
import form_item39 from './components/form_item39/form_item39.vue';
import form_item38 from './components/form_item38/form_item38.vue';
import table_exp19 from './components/table_exp19/table_exp19.vue';
import form_item37 from './components/form_item37/form_item37.vue';
import form_item36 from './components/form_item36/form_item36.vue';
import table_exp18 from './components/table_exp18/table_exp18.vue';
import form_item35 from './components/form_item35/form_item35.vue';
import form_item34 from './components/form_item34/form_item34.vue';
import table_exp17 from './components/table_exp17/table_exp17.vue';
import form_item33 from './components/form_item33/form_item33.vue';
import form_item32 from './components/form_item32/form_item32.vue';
import table_exp16 from './components/table_exp16/table_exp16.vue';
import form_item31 from './components/form_item31/form_item31.vue';
import form_item30 from './components/form_item30/form_item30.vue';
import table_exp15 from './components/table_exp15/table_exp15.vue';
import form_item29 from './components/form_item29/form_item29.vue';
import form_item28 from './components/form_item28/form_item28.vue';
import table_exp14 from './components/table_exp14/table_exp14.vue';
import form_item27 from './components/form_item27/form_item27.vue';
import form_item26 from './components/form_item26/form_item26.vue';
import table_exp13 from './components/table_exp13/table_exp13.vue';
import form_item25 from './components/form_item25/form_item25.vue';
import form_item24 from './components/form_item24/form_item24.vue';
import table_exp12 from './components/table_exp12/table_exp12.vue';
import form_item23 from './components/form_item23/form_item23.vue';
import form_item22 from './components/form_item22/form_item22.vue';
import table_exp11 from './components/table_exp11/table_exp11.vue';
import form_item21 from './components/form_item21/form_item21.vue';
import form_item20 from './components/form_item20/form_item20.vue';
import table_exp10 from './components/table_exp10/table_exp10.vue';
import form_item19 from './components/form_item19/form_item19.vue';
import form_item18 from './components/form_item18/form_item18.vue';
import table_exp9 from './components/table_exp9/table_exp9.vue';
import form_item17 from './components/form_item17/form_item17.vue';
import form_item16 from './components/form_item16/form_item16.vue';
import table_exp8 from './components/table_exp8/table_exp8.vue';
import form_item15 from './components/form_item15/form_item15.vue';
import form_item14 from './components/form_item14/form_item14.vue';
import table_exp7 from './components/table_exp7/table_exp7.vue';
import form_item13 from './components/form_item13/form_item13.vue';
import form_item12 from './components/form_item12/form_item12.vue';
import table_exp6 from './components/table_exp6/table_exp6.vue';
import form_item11 from './components/form_item11/form_item11.vue';
import form_item10 from './components/form_item10/form_item10.vue';
import table_exp5 from './components/table_exp5/table_exp5.vue';
import form_item9 from './components/form_item9/form_item9.vue';
import form_item8 from './components/form_item8/form_item8.vue';
import table_exp4 from './components/table_exp4/table_exp4.vue';
import form_item7 from './components/form_item7/form_item7.vue';
import form_item6 from './components/form_item6/form_item6.vue';
import table_exp3 from './components/table_exp3/table_exp3.vue';
import form_item5 from './components/form_item5/form_item5.vue';
import form_item4 from './components/form_item4/form_item4.vue';
import table_exp2 from './components/table_exp2/table_exp2.vue';
import form_item3 from './components/form_item3/form_item3.vue';
import form_item2 from './components/form_item2/form_item2.vue';
import table_exp1 from './components/table_exp1/table_exp1.vue';
import form_item1 from './components/form_item1/form_item1.vue';
import topNav from "./components/topNav/topNav.vue";
import footerVue from "@/components/footerVue/footerVue.vue";
import table_exp from "@/components/table_exp/table_exp.vue";
import tabs from "@/components/tabs/tabs.vue";

const components = {
form_item53,
form_item52,
table_exp26,
form_item51,
form_item50,
table_exp25,
form_item49,
form_item48,
table_exp24,
form_item47,
form_item46,
table_exp23,
form_item45,
form_item44,
table_exp22,
form_item43,
form_item42,
table_exp21,
form_item41,
form_item40,
table_exp20,
form_item39,
form_item38,
table_exp19,
form_item37,
form_item36,
table_exp18,
form_item35,
form_item34,
table_exp17,
form_item33,
form_item32,
table_exp16,
form_item31,
form_item30,
table_exp15,
form_item29,
form_item28,
table_exp14,
form_item27,
form_item26,
table_exp13,
form_item25,
form_item24,
table_exp12,
form_item23,
form_item22,
table_exp11,
form_item21,
form_item20,
table_exp10,
form_item19,
form_item18,
table_exp9,
form_item17,
form_item16,
table_exp8,
form_item15,
form_item14,
table_exp7,
form_item13,
form_item12,
table_exp6,
form_item11,
form_item10,
table_exp5,
form_item9,
form_item8,
table_exp4,
form_item7,
form_item6,
table_exp3,
form_item5,
form_item4,
table_exp2,
form_item3,
form_item2,
table_exp1,
form_item1,
    rightNav,
    topNav,
    footerVue,
    table_exp,
    tabs
}

const data = function () {
    return {
        comName: "index",
        isShowRight: true,
        ssdata: []
    }
}

function mounted() {
}

const methods = {
    showRight() {
        if (this.isShowRight == true) {
            this.isShowRight = false
        } else {
            this.isShowRight = true
        }
    }
}

export default {
    data: data,
    methods: methods,
    mounted: mounted,
    components: components,
}