import axios from "axios";
import addoredit from "../dialog/dialog.vue";

let axios_config = require("./config")

const components = {addoredit}

const data = function () {
    return {
        tableData: [
            {
                name: "ni",
                pwd: "233"
            }
        ],
        column: [
            {
                label: "名称",
                field_name: "name",
                type: "input"
            },
            {
                label: "密码",
                field_name: "pwd",
                type: "select"
            }
        ],
        search: {}, //搜索输入内容存储
        dialogTableVisible1: false, //显示新增弹窗控制变量
        dialogform: {
            uuid: "sss"
        },
        component_name: "index",
        form: {
            name: "xbc"
        },
        currentPage3: 1,
        all_selections: [],
        activeName: "1",
        filtername: "",
        now_selection_record: ""
    }
}

function mounted() {
    const {url, method, data: originalData, then} = axios_config.mounted;
    const data = JSON.parse(JSON.stringify(originalData));
    let new_data = this.get_config_data(data)
    // 发起请求
    axios({
        method,
        url,
        data: new_data,
    }).then(res => {
        // 执行回调函数（then回调）
        eval(then)
    })
}

const methods = {
    get_config_data(config_data) {
        console.log(config_data)
        // 执行转译data
        let new_data = {
            "interaction_name": config_data["interaction_name"],
            "return_data": config_data["return_data"],
            "submit_data": {
                "interaction_source_name": config_data["submit_data"]["interaction_source_name"],
                "interaction_source_uuid": config_data["submit_data"]["interaction_source_uuid"],
                "interaction_object_type": config_data["submit_data"]["interaction_object_type"],
                "interaction_style": config_data["submit_data"]["interaction_style"],
                "target_table": config_data["submit_data"]["target_table"],
                "target_api_url": config_data["submit_data"]["target_api_url"],
                "target_api_ID": config_data["submit_data"]["target_api_ID"],
            }
        }
        let re = this.format_data(config_data["submit_data"]["data"])
        new_data["submit_data"]["data"] = re
        return new_data
    },
    format_data(dict) {
        if (typeof dict == "string") {
            dict = eval(dict)
        } else {
            for (const key in dict) {
                let value = dict[key]
                if (typeof value == "object") {
                    this.format_data(value)
                } else {
                    if (typeof value == 'number') {
                        dict[key] = value
                    } else {
                        if (value.includes("this.")) {
                            if (dict.hasOwnProperty(key)) {
                                dict[key] = eval(value)
                            }
                        } else {
                            dict[key] = value
                        }
                    }
                }
            }
        }
        return dict
    },
    handleSelectionChange() {
        const {url, method, data: originalData, then} = axios_config.handleSelectionChange;
        const data = JSON.parse(JSON.stringify(originalData));
        let new_data = this.get_config_data(data)
        // 发起请求
        axios({
            method,
            url,
            data: new_data,
        }).then(res => {
            // 执行回调函数（then回调）
            eval(then)
        })
    },
    del_data() {
        const {url, method, data: originalData, then} = axios_config.del_data;
        const data = JSON.parse(JSON.stringify(originalData));
        let new_data = this.get_config_data(data)
        // 发起请求
        axios({
            method,
            url,
            data: new_data,
        }).then(res => {
            // 执行回调函数（then回调）
            eval(then)
        })
    },
    export_data() {
        const {url, method, data: originalData, then} = axios_config.export_data;
        const data = JSON.parse(JSON.stringify(originalData));
        let new_data = this.get_config_data(data)

        // 发起请求
        axios({
            method,
            url,
            data: new_data,
            responseType: 'blob'
        }).then(response => {
            eval(then)
        })
    },
    export_temp_data() {
        const {url, method, data: originalData, then} = axios_config.export_temp_data;
        const data = JSON.parse(JSON.stringify(originalData));
        let new_data = this.get_config_data(data)
        // 发起请求
        axios({
            method,
            url,
            data: new_data,
        }).then(res => {
            // 执行回调函数（then回调）
            eval(then)
        })
    },
    coustom_button_one() {
        const {url, method, data: originalData, then} = axios_config.coustom_button_one;
        const data = JSON.parse(JSON.stringify(originalData));
        let new_data = this.get_config_data(data)
        // 发起请求
        axios({
            method,
            url,
            data: new_data,
        }).then(res => {
            // 执行回调函数（then回调）
            eval(then)
        })
    },
    coustom_button_two() {
        const {url, method, data: originalData, then} = axios_config.coustom_button_two;
        const data = JSON.parse(JSON.stringify(originalData));
        let new_data = this.get_config_data(data)
        // 发起请求
        axios({
            method,
            url,
            data: new_data,
        }).then(res => {
            // 执行回调函数（then回调）
            eval(then)
        })
    },
    handleCurrentChange(val) {
        const {url, method, data: originalData, then} = axios_config.handleCurrentChange;
        const data = JSON.parse(JSON.stringify(originalData));
        let new_data = this.get_config_data(data)
        // 发起请求
        axios({
            method,
            url,
            data: new_data,
        }).then(res => {
            // 执行回调函数（then回调）
            eval(then)
        })
    },
    adddata() {
        const {url, method, data: originalData, then} = axios_config.adddata;
        const data = JSON.parse(JSON.stringify(originalData));
        let new_data = this.get_config_data(data)
        // 发起请求
        axios({
            method,
            url,
            data: new_data,
        }).then(res => {
            // 执行回调函数（then回调）
            eval(then)
        })
    },
    delrecord(row) {
        this.now_selection_record = row.uuid
        const {url, method, data: originalData, then} = axios_config.delrecord;
        const data = JSON.parse(JSON.stringify(originalData));
        let new_data = this.get_config_data(data)
        // 发起请求
        axios({
            method,
            url,
            data: new_data,
        }).then(res => {
            // 执行回调函数（then回调）
            eval(then)
        })
    },
    editrecord(row) {
        this.now_selection_record = row.uuid
        const {url, method, data: originalData, then} = axios_config.editrecord;
        const data = JSON.parse(JSON.stringify(originalData));
        let new_data = this.get_config_data(data)
        // 发起请求
        axios({
            method,
            url,
            data: new_data,
        }).then(res => {
            // 执行回调函数（then回调）
            eval(then)
        })
    },
    searchrecord() {
        const {url, method, data: originalData, then} = axios_config.searchrecord;
        const data = JSON.parse(JSON.stringify(originalData));
        let new_data = this.get_config_data(data)
        // 发起请求
        axios({
            method,
            url,
            data: new_data,
        }).then(res => {
            // 执行回调函数（then回调）
            eval(then)
        })
    },
    updateShow(val) {
        this.dialogTableVisible1 = val
    },
    coustomOne(row) {
        this.now_selection_record = row.uuid
        const {url, method, data: originalData, then} = axios_config.coustomOne;
        const data = JSON.parse(JSON.stringify(originalData));
        let new_data = this.get_config_data(data)
        // 发起请求
        axios({
            method,
            url,
            data: new_data,
        }).then(res => {
            // 执行回调函数（then回调）
            eval(then)
        })
    },
    coustomTwo(row) {
        this.now_selection_record = row.uuid
        const {url, method, data: originalData, then} = axios_config.coustomTwo;
        const data = JSON.parse(JSON.stringify(originalData));
        let new_data = this.get_config_data(data)
        // 发起请求
        axios({
            method,
            url,
            data: new_data,
        }).then(res => {
            // 执行回调函数（then回调）
            eval(then)
        })
    },
    handleClick(val) {
        this.search = {}
        this.search[val.props.name] = val.props.label
        const {url, method, data: originalData, then} = axios_config.handleClick;
        const data = JSON.parse(JSON.stringify(originalData));
        let new_data = this.get_config_data(data)
        // 发起请求
        axios({
            method,
            url,
            data: new_data,
        }).then(res => {
            // 执行回调函数（then回调）
            eval(then)
        })
    }
}

export default {
    data: data,
    methods: methods,
    mounted: mounted,
    components: components,
}