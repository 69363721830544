<template>
  <el-menu class="el-menu-demo" mode="horizontal" :ellipsis="false" background-color="#000000" text-color="#fff"
           @select="handleSelect" active-text-color="#ffd04b">
    <el-menu-item style="font-size: 20px; color: #ffd04b" index="0" title="用户导入模版-topNav-el-menu-item-3">
      ERP
    </el-menu-item>
    <submenu :item="this.params.top_nav_param.submenuLevelOne.value">
    </submenu>
  </el-menu>
</template>
<script src="./topNav.js">
</script>
<style scoped src="./topNav.css">
</style>
