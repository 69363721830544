import axios from "axios";
import submenu from "@/components/topNav/submenuTop.vue";

let PARAMAndDATA = require('../../../public/static/basedata/topInitalize.json')

const components = {
    submenu
}

const data = function () {
    return {
        params: PARAMAndDATA,
    }
}

function mounted() {
}

const methods = {
    handleSelect(key, keypath, items) {
        this.params.top_nav_param.submenuLevelOne.value.forEach(element => {
            if (element.uuid == keypath[0]) {
                if (element.children.length == 0) {
                    this.$parent.$root.comName = element.com_name
                } else {
                    element.children.forEach(item => {
                        if (item.uuid == keypath[1]) {
                            this.$parent.$root.comName = item.com_name
                        }
                    })
                }
            }
        });
    },
    // handleSelect(key, keyPath) {
    //     if (key === "2-1") {
    //         this.$parent.$root.comName = "user";
    //     }
    //     if (key === "2-2") {
    //         this.$parent.$root.comName = "login";
    //     }
    //     if (key === "3") {
    //         this.$parent.$root.comName = "index";
    //     }
    //     if (key === "4") {
    //         this.$parent.$root.comName = "indexOne";
    //     }
    //     if (key === "5") {
    //         this.$parent.$root.comName = "order_two";
    //     }
    //     if (key === "6") {
    //         this.$parent.$root.comName = "order_list";
    //     }
    //     if (key === "7") {
    //         this.$parent.$root.comName = "address";
    //     }
    //     if (key === "8") {
    //         this.$parent.$root.comName = "user";
    //     }
    //     if (key === "9") {
    //         this.$parent.$root.comName = "fund";
    //     }
    //     if (key === "10") {
    //         this.$parent.$root.comName = "custom";
    //     }
    // },
}

export default {
    data: data,
    methods: methods,
    mounted: mounted,
    components: components,
}