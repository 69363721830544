<template>
  <div v-for="itemssss in item" :key="itemssss.level" :class="{flexgrow:itemssss.div_class == null}">
    <div v-if="itemssss.menu_url != null">
      <el-menu-item :index="itemssss.uuid" :indexPath="itemssss.menu_url">
        <el-icon v-if="itemssss.menu_icon != null">
          <User/>
        </el-icon>
        <span>{{ itemssss.menu_name }}</span>
      </el-menu-item>
    </div>
    <div v-if="itemssss.menu_url == null" :class="{flexgrow:true}">
      <div class="flex-grow"/>
      <el-sub-menu :index="itemssss.uuid">
        <template #title>
          <el-icon v-if="itemssss.menu_icon != null">
            <User/>
          </el-icon>
          <span v-if="itemssss.menu_name == null">{{ itemssss.menu_name }}</span>
        </template>
        <div
            v-if="itemssss.children && itemssss.children.length != 0"
        >
          <subMenu :item="itemssss.children"></subMenu>
        </div>
      </el-sub-menu>
    </div>
  </div>
</template>

<script src="./submenuTop.js"></script>

<style src="./submenuTop.css" scoped></style>