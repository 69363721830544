import axios from "axios";

const components = {

}

const data = function () {
    return {
    }
}

function mounted() {
}

const methods = {

}

export default {
    data: data,
    methods: methods,
    mounted: mounted,
    components: components,
}