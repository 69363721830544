<template>
  <el-container style="">
    <!--    <el-affix :offset="240" style="text-align: right; height: 0px">-->
    <!--      <el-button type="primary"-->
    <!--      >购物车-->
    <!--        <el-icon size="60px">-->
    <!--          <ShoppingCart/>-->
    <!--        </el-icon>-->
    <!--      </el-button>-->
    <!--    </el-affix>-->
    <el-header :style="{backgroundColor:'#000000'}">
      <topNav/>
    </el-header>
    <el-container>
      <el-aside width="200px" v-show="isShowRight" :style="{backgroundColor:'#000000'}">
        <rightNav/>
      </el-aside>
      <div class="half-circle" @click="showRight">
        <el-icon>
          <ArrowRightBold/>
        </el-icon>
      </div>
      <el-main style="background-color: white; height: 816px">
        <component :is="comName" :List="ssdata"></component>
      </el-main>
    </el-container>
    <el-footer :style="{backgroundColor:'#000000'}">
      <footerVue/>
    </el-footer>
  </el-container>
</template>

<script src="./App.js"></script>

<style src="./App.css" scoped></style>
