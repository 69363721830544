<template>
  <el-dialog :title="title" v-model="DialogShow" width="50%" :before-close="closeDg">
    <component :is="componentname" :formdatato="formdata">
</component>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="DialogShow = false" title="用户导入模版-dialog-el-button-7">
Cancel</el-button>
        <el-button type="primary" @click="comfrom" title="用户导入模版-dialog-el-button-9">
 Confirm        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script src="./dialog.js">
</script>
<style scoped src="./dialog.css">
</style>
