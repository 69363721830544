<template>
  <el-menu class="el-menu-demo" mode="horizontal" :ellipsis="false" background-color="#000" text-color="#fff">
    <div class="flex-grow"/>
    <el-menu-item style="text-align: center" index="3" title="用户导入模版-footerVue-el-menu-item-4">
      © 2023 ERP 测试
    </el-menu-item>
    <div class="flex-grow"/>
  </el-menu>
</template>
<script src="./footerVue.js">
</script>
<style src="./footerVue.css" scoped>
</style>
