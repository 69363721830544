import {
    User
} from "@element-plus/icons-vue";
import axios from "axios";

const name = "subMenu"

const components = {
    User
}

const props = ["item"]

const data = function () {
    return {

    };
}

const computed = {
}

function mounted() {
}

const methods = {
}

export default {
    name: name,
    data: data,
    methods: methods,
    mounted: mounted,
    computed: computed,
    props: props,
    components: components
}