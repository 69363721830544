<template>
  <div>
    <el-form :model="dialogform" ref="form" label-width="80px" title="用户导入模版-form_item1-el-form-3">
      <el-form-item v-for="item in column" :label="item.recorder_attribute_dict.field_cn_name">
        <el-input v-model="item.recorder_attribute_dict.form_item_value" placeholder="请输入" v-if="item.recorder_attribute_dict.form_item_type == 'input'" class="input">
        </el-input>
        <el-select v-else-if="item.recorder_attribute_dict.form_item_type == 'select'" v-model="item.recorder_attribute_dict.form_item_value" placeholder="请选择" title="用户导入模版-form_item1-el-select-7">
          <el-option v-for='option in item.recorder_attribute_dict.options' :label='option.label' :value='option.value' :key="option.value">
          </el-option>
        </el-select>
        <el-table :data="item.recorder_attribute_dict.tableData" style="width: 90%" :border="true" @selection-change="handleSelectionChange($event,item)" v-else title="用户导入模版-form_item1-el-table-11" :ref="item.recorder_attribute_dict.form_item_table">
          <el-table-column type='selection'>
          </el-table-column>
          <el-table-column v-for='columns in item.recorder_attribute_dict.table_columns' :prop='columns.field_en_name' :label='columns.field_cn_name'>
            <template #default="scope">
              <el-progress :percentage="scope.row[columns.field_name]" v-if="columns.field_name == 'status_progress'"/>
              <span v-else>
 {{ scope.row[columns.field_en_name] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
    </el-form>
    <div class="button_box">
      <el-button type="primary" @click="onSubmit" title="用户导入模版-form_item1-el-button-25">
 提 交      </el-button>
      <el-button v-if="false" @click="coustomOne" title="用户导入模版-form_item1-el-button-27">
 取 消      </el-button>
      <el-button v-if="false" @click="coustomTwo" title="用户导入模版-form_item1-el-button-29">
 取 消      </el-button>
      <el-button v-if="false" @click="coustomThree" title="用户导入模版-form_item1-el-button-31">
 取 消      </el-button>
    </div>
  </div>
</template>
<script src="./form_item1.js">
</script>
<style scoped src="./form_item1.css">
</style>
