<template>
  <el-menu @select="haneleselect" text-color="#fff" background-color="#000000">
    <submenu :item="this.params.left_nav_param.submenuLevelOne.value">
</submenu>
  </el-menu>
</template>
<script src="./rightNavJS.js">
</script>
<style src="./rightNav.css" scoped>
</style>
