let PARAMAndDATA = require('../../../public/static/basedata/rightInitalize.json')


import submenu from './submenuRight.vue'
import axios from 'axios'
import {reactive, toRaw} from 'vue';
import {v3} from 'uuid'

const components = {
    submenu
}

const data = function () {
    return {
        sub_menu: false,
        defaultTableUUID: "",
        params: PARAMAndDATA,

    }
}

function mounted() {
    const rawObj = toRaw(this.params);
}

const methods = {
    haneleselect(key, keypath, item) {
        this.params.left_nav_param.submenuLevelOne.value.forEach(element => {
            if (element.uuid == keypath[0]) {
                if (element.children.length == 0) {
                    this.$parent.$root.comName = element.com_name
                } else {
                    element.children.forEach(item => {
                        if (item.uuid == keypath[1]) {
                            this.$parent.$root.comName = item.com_name
                        }
                    })
                }
            }
        });
    }
}

export default {
    data: data,
    methods: methods,
    mounted: mounted,
    components: components,
}