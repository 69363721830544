<template>
  <div v-if="item && item.length != 0" class="item">
    <div v-for="itemssss in item" :key="itemssss.level" class="item">
      <div v-if="itemssss.menu_url != null" class="item">
        <el-menu-item :index="itemssss.uuid" :indexPath="itemssss.menu_url">
          <el-icon><User /></el-icon>
          <span>{{ itemssss.menu_name }}</span>
        </el-menu-item>
      </div>
      <div v-else>
        <el-sub-menu :index="itemssss.uuid">
          <template #title>
            <el-icon><User /></el-icon>
            <span>{{ itemssss.menu_name }}</span>
          </template>
          <div
            v-if="itemssss.children && itemssss.children.length != 0"
            class="item"
          >
            <subMenu :item="itemssss.children"></subMenu>
          </div>
        </el-sub-menu>
      </div>
    </div>
  </div>
</template>

<script src="./submenuRight.js"></script>

<style src="./submenuRight.css" scoped></style>