import axios from "axios";

let axios_config = require("./config")

const props = ["formdatato"]
const emits = []
const components = {}

const data = function () {
    return {
        dialogform: {},
        column: [],
        now_selections: []
    }
}

function mounted() {
    // 解构数据
    const {url, method, data: originalData, then} = axios_config.mounted;
    const data = JSON.parse(JSON.stringify(originalData));
    let new_data = this.get_config_data(data)
    if (sessionStorage.getItem("now_select")) {
        new_data["submit_data"]["now_select_record"] = sessionStorage.getItem("now_select")
        sessionStorage.removeItem("now_select")
    }
    // 发起请求
    axios({
        method,
        url,
        data: new_data,
    }).then(res => {
        // 执行回调函数（then回调）
        eval(then)
        // this.show_data(res.data.data.return_data.data)
    })
    if (Object.keys(this.formdatato).length != 0) {
        this.dialogform = this.formdatato
    }
}

const methods = {
    // // 数据回显
    // show_data(data) {
    //     data.forEach(item => {
    //         if (item.recorder_attribute_dict.form_item_table != "") {
    //             let table_name = item.recorder_attribute_dict.form_item_table
    //             this.$nextTick(() => {
    //                 item.recorder_attribute_dict.form_item_value.forEach(item => {
    //                     for (let i = 0; i < this.$refs[table_name].length; i++) {
    //                         this.$refs[table_name][i].toggleRowSelection(item, true)
    //                     }
    //                 })
    //             });
    //         }
    //     })
    // },
    get_config_data(config_data) {
        console.log(config_data)
        // 执行转译data
        let new_data = {
            "interaction_name": config_data["interaction_name"],
            "return_data": config_data["return_data"],
            "submit_data": {
                "interaction_source_name": config_data["submit_data"]["interaction_source_name"],
                "interaction_source_uuid": config_data["submit_data"]["interaction_source_uuid"],
                "interaction_object_type": config_data["submit_data"]["interaction_object_type"],
                "interaction_style": config_data["submit_data"]["interaction_style"],
                "target_table": config_data["submit_data"]["target_table"],
                "target_api_url": config_data["submit_data"]["target_api_url"],
                "target_api_ID": config_data["submit_data"]["target_api_ID"],
            }
        }
        let re = this.format_data(config_data["submit_data"]["data"])
        new_data["submit_data"]["data"] = re
        return new_data
    },
    format_data(dict) {
        if (typeof dict == "string") {
            dict = eval(dict)
        } else {
            for (const key in dict) {
                let value = dict[key]
                if (typeof value == "object") {
                    this.format_data(value)
                } else {
                    if (typeof value == 'number') {
                        dict[key] = value
                    } else {
                        if (value.includes("this.")) {
                            if (dict.hasOwnProperty(key)) {
                                dict[key] = eval(value)
                            }
                        } else {
                            dict[key] = value
                        }
                    }
                }
            }
        }
        return dict
    },
    handleSelectionChange(val, item) {
        this.now_selections = val
        item.recorder_attribute_dict.form_item_value = val
    },
    onSubmit() {
        const {url, method, data: originalData, then} = axios_config.onSubmit;
        const data = JSON.parse(JSON.stringify(originalData));
        let new_data = this.get_config_data(data)
        // 发起请求
        axios({
            method,
            url,
            data: new_data,
        }).then(res => {
            // 执行回调函数（then回调）
            eval(then)
        })
    },
    coustomOne() {
        const {url, method, data: originalData, then} = axios_config.coustomOne;
        const data = JSON.parse(JSON.stringify(originalData));
        let new_data = this.get_config_data(data)
        // 发起请求
        axios({
            method,
            url,
            data: new_data,
        }).then(res => {
            // 执行回调函数（then回调）
            eval(then)
        })
    },
    coustomTwo() {
        const {url, method, data: originalData, then} = axios_config.coustomTwo;
        const data = JSON.parse(JSON.stringify(originalData));
        let new_data = this.get_config_data(data)
        // 发起请求
        axios({
            method,
            url,
            data: new_data,
        }).then(res => {
            // 执行回调函数（then回调）
            eval(then)
        })
    },
    coustomThree() {
        const {url, method, data: originalData, then} = axios_config.coustomThree;
        const data = JSON.parse(JSON.stringify(originalData));
        let new_data = this.get_config_data(data)
        // 发起请求
        axios({
            method,
            url,
            data: new_data,
        }).then(res => {
            // 执行回调函数（then回调）
            eval(then)
        })
    },
}

export default {
    data: data,
    methods: methods,
    mounted: mounted,
    components: components,
    props: props,
    emits: emits
}