<template>
  <div class="table_tabs">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick" title="用户导入模版-tabs-el-tabs-3">
      <el-tab-pane v-for="(item,index) in tab_list" :key="index" :label="item.label" :name="item.name">
</el-tab-pane>
    </el-tabs>
    <component :is="componentname">
</component>
  </div>
</template>
<script src="./tabs.js">
</script>
<style scoped src="./tabs.css">
</style>
