import axios from "axios";
import form_item44 from '../form_item44/form_item44.vue';
import form_item30 from '../form_item30/form_item30.vue';
import form_item27 from '../form_item27/form_item27.vue';
import form_item28 from '../form_item28/form_item28.vue';
import form_item26 from '../form_item26/form_item26.vue';
import form_item22 from '../form_item22/form_item22.vue';
import form_item43 from '../form_item43/form_item43.vue';
import form_item25 from '../form_item25/form_item25.vue';
import form_item24 from '../form_item24/form_item24.vue';
import form_item15 from '../form_item15/form_item15.vue';
import form_item14 from '../form_item14/form_item14.vue';
import form_item19 from '../form_item19/form_item19.vue';
import form_item18 from '../form_item18/form_item18.vue';
import form_item17 from '../form_item17/form_item17.vue';
import form_item16 from '../form_item16/form_item16.vue';
import form_item41 from '../form_item41/form_item41.vue';
import form_item40 from '../form_item40/form_item40.vue';
import form_item42 from '../form_item42/form_item42.vue';
import form_item13 from '../form_item13/form_item13.vue';
import form_item12 from '../form_item12/form_item12.vue';
import form_item11 from '../form_item11/form_item11.vue';
import form_item10 from '../form_item10/form_item10.vue';
import form_item9 from '../form_item9/form_item9.vue';
import form_item8 from '../form_item8/form_item8.vue';
import form_item7 from '../form_item7/form_item7.vue';
import form_item6 from '../form_item6/form_item6.vue';
import form_item5 from '../form_item5/form_item5.vue';
import form_item4 from '../form_item4/form_item4.vue';
import table_exp1 from '../table_exp1/table_exp1.vue';
import form_item3 from '../form_item3/form_item3.vue';
import form_item2 from '../form_item2/form_item2.vue';
import form_item1 from '../form_item1/form_item1.vue';
import form_item from "../form_item/form_item.vue"


const props = ["DialogShow", "componentname", "formdata"]
const emits = ['update-show']
const components = {
  form_item44,
  form_item30,
  form_item27,
  form_item28,
  form_item26,
  form_item22,
  form_item43,
  form_item25,
  form_item24,
  form_item15,
  form_item14,
  form_item19,
  form_item18,
  form_item17,
  form_item16,
  form_item41,
  form_item40,
  form_item42,
  form_item13,
  form_item12,
  form_item11,
  form_item10,
  form_item9,
  form_item8,
  form_item7,
  form_item6,
  form_item5,
  form_item4,
  table_exp1,
  form_item3,
  form_item2,
  form_item1,
    form_item,
}

const data = function () {
    return {
        components
    }
}

function mounted() {
    if (this.row != {}) {
        this.dialogform = this.row
    }
}

const methods = {
    closeDg() {
        this.$emit('update-show', false) // 触发父事件，并传布尔值false
    },
    comfrom() {
        this.$emit('update-show', false) // 触发父事件，并传布尔值false
        console.log(this.dialogform)
    },
}

export default {
    data: data,
    methods: methods,
    mounted: mounted,
    components: components,
    props: props,
    emits: emits
}