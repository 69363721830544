<template>
  <div class="daorubox">
    <div class="tab_box">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick" title="用户导入模版-table_exp25-el-tabs-4">
        <el-tab-pane label="全部" name="all" v-if="false">
        </el-tab-pane>
        <el-tab-pane label="半成品" name="half" v-if="true">
        </el-tab-pane>
        <el-tab-pane label="残次品" name="can" v-if="true">
        </el-tab-pane>
        <el-tab-pane label="原料" name="yuan" v-if="true">
        </el-tab-pane>
        <el-tab-pane label="返销品" name="return" v-if="true">
        </el-tab-pane>
        <el-tab-pane label="默认6" name="默认6" v-if="false">
        </el-tab-pane>
        <el-tab-pane label="默认7" name="默认7" v-if="false">
        </el-tab-pane>
        <el-tab-pane label="默认8" name="默认8" v-if="false">
        </el-tab-pane>
        <el-tab-pane label="默认9" name="默认9" v-if="false">
        </el-tab-pane>
        <el-tab-pane label="默认10" name="默认10" v-if="false">
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="searchbox">
      <el-input v-for="inputs in column" :key="inputs.field_en_name" v-model="search[inputs.field_en_name]" :placeholder="'请输入'+ inputs.field_cn_name" v-if="true" class="inputs">
      </el-input>
      <el-button @click="searchrecord" v-if="true" class="searchinput" title="用户导入模版-table_exp25-el-button-30">
 搜索      </el-button>
    </div>
    <div class="searchbox">
      <el-button type="success" @click="adddata" v-if="true" class="searchinput" title="用户导入模版-table_exp25-el-button-34">
 新增      </el-button>
      <el-button type="success" @click="del_data" v-if="true" class="searchinput" title="用户导入模版-table_exp25-el-button-36">
 删除      </el-button>
      <el-button type="success" @click="export_data" v-if="true" class="searchinput" title="用户导入模版-table_exp25-el-button-38">
 导出      </el-button>
      <el-button type="success" @click="export_temp_data" v-if="true" class="searchinput" title="用户导入模版-table_exp25-el-button-40">
 导出模版      </el-button>
      <el-button type="success" @click="coustom_button_one" v-if="false" class="searchinput" title="用户导入模版-table_exp25-el-button-42">
 自定按钮1      </el-button>
      <el-button type="success" @click="coustom_button_two" v-if="false" class="searchinput" title="用户导入模版-table_exp25-el-button-44">
 自定按钮2      </el-button>
    </div>
    <el-table :data="tableData" style="width: 90%" border @selection-change="handleSelectionChange" title="用户导入模版-table_exp25-el-table-47">
      <el-table-column type="selection">
      </el-table-column>
      <el-table-column v-for="columns in column" :prop="columns.field_en_name" :label="columns.field_cn_name">
        <template #default="scope">
          <el-progress :percentage="scope.row[columns.field_en_name]" v-if="columns.field_en_name == 'status_progress'"/>
          <span v-else>
 {{ scope.row[columns.field_en_name] }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="Operations">
        <template #default="scope">
          <el-button @click="delrecord(scope.row)" size="small" v-if="true" title="用户导入模版-table_exp25-el-button-59">
 删除          </el-button>
          <el-button @click="editrecord(scope.row)" size="small" v-if="true" title="用户导入模版-table_exp25-el-button-61">
 编辑          </el-button>
          <el-button @click="coustomOne(scope.row)" size="small" v-if="false" title="用户导入模版-table_exp25-el-button-63">
 审核          </el-button>
          <el-button @click="coustomTwo(scope.row)" size="small" v-if="false" title="用户导入模版-table_exp25-el-button-65">
 自定按键          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage3" :page-size="100" layout="prev, pager, next, jumper" :total="100">
    </el-pagination>
    <div v-if="dialogTableVisible1">
      <addoredit v-model:DialogShow="dialogTableVisible1" @update-show="updateShow" :componentname="component_name" :formdata="dialogform">
      </addoredit>
    </div>
  </div>
</template>
<script src="./table_exp25.js">
</script>
<style scoped src="./table_exp25.css">
</style>
